<template>
  <section>
    <div v-if="isLoading">
      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
    <section v-else>
      <p class="has-text-weight-bold mb-2">Sales history</p>

      <b-table
        class="pt-4"
        :data="salesHistory"
        ref="table"
        paginated
        per-page="10"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        pagination-rounded
      >
        <b-table-column field="selectedPass" label="Item" v-slot="props">
          {{ props.row.selectedPass }}
        </b-table-column>

        <b-table-column field="date" label="Date" v-slot="props" sortable>
          {{ props.row.date }}
        </b-table-column>

        <b-table-column
          field="numberOfTickets"
          label="Tickets sold"
          sortable
          v-slot="props"
        >
          {{ props.row.numberOfTickets }}
        </b-table-column>

        <b-table-column
          field="totalPrice"
          label="Purchase value"
          sortable
          v-slot="props"
        >
          {{ parseFloat(props.row.totalPrice).toFixed(2) }} €
        </b-table-column>

        <b-table-column
          v-if="role !== ResellerRoles.driver"
          field="commission"
          label="Commission Earned"
          sortable
          v-slot="props"
        >
          {{ props.row.commission }}
        </b-table-column>

        <b-table-column
          v-if="role !== ResellerRoles.driver"
          field="paid"
          label="Payment Status"
          sortable
          v-slot="props"
        >
          <b-tag v-if="props.row.paid" type="is-success">Paid</b-tag>
          <b-tag v-else type="is-warning">Payment pending</b-tag>
        </b-table-column>

        <b-table-column v-if="isAdminView" v-slot="props">
          <b-button
            :disabled="props.row.paid"
            @click="payCommission([props.row.id])"
            >Mark as paid</b-button
          >
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">You haven't sold any tickets yet</div>
        </template>
      </b-table>
    </section>
  </section>
</template>

<script>
import { formattedDate } from "../helpers";
import { ResellerRoles } from "../enums/ResellerRoles";
import { db } from "../firebase";
export default {
  props: {
    sales: {
      type: Array,
      default: () => [],
    },
    activities: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: "",
    },
    vendorId: {
      type: String,
      default: null,
    },
    isAdminView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formattedDate,
      checkedRows: [],
      closing: false,
      isLoading: true,
      salesHistory: [],
      ResellerRoles,
      salesLoaded: false,
    };
  },
  created() {
    if (!this.isAdminView) this.processSalesData();
    this.isLoading = false;
  },
  watch: {
    sales() {
      if (!this.isAdminView) return;
      if (!this.sales.length || this.salesLoaded) return;
      this.processSalesData();
      this.salesLoaded = true;
    },
  },
  methods: {
    getPassTitleById(id) {
      const activityIndex = this.activities.findIndex(
        (activity) => activity.id === id
      );
      return this.activities[activityIndex].title;
    },
    formatTimestamp(timestamp) {
      let date;
      if (typeof timestamp === "object" && timestamp.seconds !== undefined) {
        // Handle seconds and nanoseconds format
        const { seconds, nanoseconds } = timestamp;
        const milliseconds = seconds * 1000 + nanoseconds / 1e6;
        date = new Date(milliseconds);
      } else {
        // Handle ISO 8601 format
        date = new Date(timestamp);
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
    processSalesData() {
      const sortedDates = this.sales.sort((a, b) => {
        // Sort by seconds and nanoseconds if they exist (for timestamp objects)
        if (
          typeof b.dateOfPurchase === "object" &&
          b.dateOfPurchase.seconds !== undefined
        ) {
          if (b.dateOfPurchase.seconds !== a.dateOfPurchase.seconds) {
            return b.dateOfPurchase.seconds - a.dateOfPurchase.seconds;
          }
          return b.dateOfPurchase.nanoseconds - a.dateOfPurchase.nanoseconds;
        } else {
          // If ISO 8601 date string, compare based on the Date object
          return new Date(b.dateOfPurchase) - new Date(a.dateOfPurchase);
        }
      });
      console.log(this.salesHistory);
      sortedDates.map((sale) =>
        this.salesHistory.push({
          date: this.formatTimestamp(sale.dateOfPurchase),
          numberOfTickets: sale.numberOfTickets,
          selectedPass: this.getPassTitleById(sale.selectedPass),
          totalPrice: sale.totalPrice,
          commission: sale.commission
            ? `${parseFloat(sale.commission).toFixed(2)} €`
            : "-",
          paid: sale.paid,
          id: sale.ticketId,
        })
      );
    },
    async payCommission(ids) {
      try {
        const vendorRef = db
          .firestore()
          .collection("vendors")
          .doc(this.vendorId);
        const vendorSnapshot = await vendorRef.get();

        if (!vendorSnapshot.exists) {
          console.error("❌ Vendor not found!");
          return;
        }

        const vendorData = vendorSnapshot.data();
        if (
          !vendorData.salesHistory ||
          !Array.isArray(vendorData.salesHistory)
        ) {
          console.error("❌ No sales history found!");
          return;
        }

        let updated = false;

        // Loop through each element in salesHistory and update paid if ticketId is in ids array
        const updatedSalesHistory = vendorData.salesHistory.map((sale) => {
          if (ids.includes(sale.ticketId)) {
            updated = true;
            return { ...sale, paid: true };
          }
          return sale;
        });

        // Only update Firestore if there were changes
        if (updated) {
          await vendorRef.update({ salesHistory: updatedSalesHistory });
          this.updateTableData(ids);
          console.log("✅ All matching tickets updated successfully!");
        } else {
          console.log("⚠️ No matching tickets found.");
        }
      } catch (error) {
        console.error("❌ Error updating tickets:", error);
      }
    },
    updateTableData(ids) {
      ids.forEach((id) => {
        const index = this.salesHistory.findIndex((ticket) => ticket.id === id);
        if (index !== -1) {
          this.salesHistory[index].paid = true; // Set paid to true
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container--info {
  display: block;
  padding: 1rem;

  .columns {
    align-items: flex-start;
  }
}

.vendor-details {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  max-width: 10rem;
  color: #373f4f;
  font-weight: 300;
}
</style>
