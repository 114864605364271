<template>
  <section class="container-md">
    <section>
      <p class="is-size-5 is-blue mb-4" :class="{'mt-6': !isMobileView}">
        Welcome back
        <strong class="is-blue" v-if="user?.displayName"
          >{{ user?.displayName }}! 👋</strong
        >
      </p>

      <b-field
        label="Your referral link"
        class="mb-6 mt-5"
        v-if="user?.role === ResellerRoles.hotel"
      >
        <b-input disabled :placeholder="referralLink" expanded></b-input>
        <div @click="copyTextToClipboard(referralLink)">
          <b-icon pack="far" class="icon-container" icon="copy"></b-icon>
        </div>
      </b-field>

      <div
        class="columns is-mobile mt-3 pl-2 is-multiline mb-5"
        v-if="user?.role !== ResellerRoles.driver"
      >
        <div
          class="column info-container"
        >
          <b class="mb-3">Tickets sold</b>
          <p class="info-text">{{ ticketsSold }}</p>
        </div>

        <div
          class="column info-container "
        >
          <b class="mb-3">Commission earned</b>
          <p class="info-text">{{ parseFloat(commissionEarned.toFixed(2)) }} €</p>
        </div>

        <div
          class="column info-container"
        >
          <b class="mb-3">Outstanding Payments</b>
          <p class="info-text">{{ parseFloat(outstandingPayments.toFixed(2))  }} €</p>
        </div>

        <div
          class="column info-container"
        >
          <b class="mb-3">Total Payouts</b>
          <p class="info-text">{{parseFloat(totalPayouts.toFixed(2))   }} €</p>
        </div>
      </div>

      <div
      v-if="user?.role !== ResellerRoles.hotel && !isMobileView"
        class="widgets mt-5 mb-5 columns"
        :class="{ 'is-mobile-view': isMobileView }"
      >
        <router-link
         class="column is-6 is-one-third-widescreen is-one-quarter-fullhd"
          :to="{ name: 'generate-ticket' }"
        >
          <div class="button-container">
            <b-icon pack="fas" icon="magic" class="mb-4"></b-icon>
            <p class="title mb-2">Create ticket</p>
            <p class="has-text-weight-light">
              Send tickets to clients after purchase
            </p>
          </div>
        </router-link>
      </div>

      <router-link
          v-if="user?.role === ResellerRoles.driver"
         :to="{ name: 'vendor-ticket-scanner' }"
        >
          <div class="fixed-button">
            <img src="../assets/scanner.svg" />
          </div>
        </router-link>

      <router-link
      class="button-container mobile"
        v-if="user?.role !== ResellerRoles.hotel && isMobileView"
        :to="{ name: 'generate-ticket' }"
      >
        <div  class="is-flex is-align-items-center">
          <b-icon pack="fas" icon="magic" class="mr-2"></b-icon>
          <p class="title">Create ticket</p>
        </div>
      </router-link>

      <CommisionsTable
        :class="{'mt-6': user?.role === ResellerRoles.hotel,
        'mt-4': user?.role === ResellerRoles.driver ||user?.role === ResellerRoles.streetSeller}"
        :activities="activities"
        :sales="salesHistory"
        :role="user?.role"
        v-if="salesHistory.length && user?.role !== ResellerRoles.driver"
      />
    </section>

    <!--  <section class="bottom-bar">
       <button @click="startTracking">Start Tracking</button>
      <button @click="stopTracking">Stop Tracking</button>
    </section> -->
  </section>
</template>

<script>
import { ResellerRoles } from "../enums/ResellerRoles";
import { db, FieldValue } from "../firebase";
import { mapState, mapMutations } from "vuex";
import {
  UsersStates,
  ActivitiesStates,
  ActivitiesMutations,
} from "../store/Storetypes";
import { HelpersMixin } from "../mixins";
import CommisionsTable from "./CommisionsTable.vue";
//import RouteButtonWithIcon from "../components/elements/RouteButtonWithIcon.vue";
export default {
  mixins: [HelpersMixin],
  components: {
    CommisionsTable,
  },
  data() {
    return {
      isLoading: false,
      watchId: null,
      ResellerRoles,
      ticketsSold: 0,
      commissionEarned: 0,
      totalPayouts: 0,
      outstandingPayments:0,
      salesHistory: [],
    };
  },
  computed: {
    ...mapState({
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
      user: (state) => state.users[UsersStates.USER],
      isVendor: (state) => state.users[UsersStates.IS_VENDOR],
    }),
    referralLink() {
      return `https://guiders.pt?referral=${this.user.id}`;
    },
  },
  async created() {
    this.getActivityData();
    const vendorData = await db
      .firestore()
      .collection("vendors")
      .doc(this.user.id)
      .get();

    if (vendorData.exists) {
      if (!vendorData.data()?.salesHistory.length) return;
      const salesHistory = vendorData.data().salesHistory;
      this.salesHistory = salesHistory;
      salesHistory.map((sale) => {
        this.ticketsSold += Number(sale.numberOfTickets);
        if (!sale?.commission) return
        this.commissionEarned += sale?.commission;
        if (sale?.paid) this.totalPayouts += sale?.commission;
        else this.outstandingPayments += sale?.commission;
      });
    }
  },
  methods: {
    ...mapMutations({
      getActivityData: ActivitiesMutations.GET_ACTIVITY_DATA,
    }),
    copyTextToClipboard(text) {
      if (!text) return;
      this.copyToClipboard(text);
    },
    startTracking() {
      if (navigator.geolocation) {
        this.watchId = navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            db.firestore().collection("vendors").doc(this.user.id).set({
              lat: latitude,
              lng: longitude,
              active: true,
              timestamp: FieldValue.serverTimestamp(),
            });
          },
          (error) => console.error("Error getting location", error),
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      }
    },
    stopTracking() {
      if (this.watchId) {
        navigator.geolocation.clearWatch(this.watchId);
        db.firestore()
          .collection("vendors")
          .doc(this.user.id)
          .update({ active: false });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.widgets.columns:not(.is-mobile-view) {
  display: -webkit-box;
}

.icon-container {
  border-radius: 8px;
  padding: 1rem;
  background-color: #f3eeeb66;
  height: 42px;
  width: 48px;
}

.columns {
  align-items: stretch;
  justify-content: left;
  gap: 1rem;
}

.button-container {
  border-radius: 12px;
  background-color: #015840;
  padding: 20px;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &.mobile {
    width: fit-content;
    justify-self: right;
    font-size: 0.95rem;
    padding: 8px 12px;
    .title {
      font-size: 0.8rem;
    }
  }
  .title {
    color: white !important;
    font-size: 1.05rem;
  }
}

.info-container {
  padding: 20px;
  border-radius: 12px;
  background-color: #f3eeeb66;
  color: #015840a3;
  display: grid;
  align-content: space-between;
  max-width: calc(50% - 1rem); /* Adjust based on column size */
  .info-text {
    font-size: 2rem;
    color: #015840;
  }
}

.fixed-button {
  position: fixed;
  bottom: 33px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #0e3d4d;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  z-index: 1000;
  img {
    height: 32px;
  }
}

.fixed-button:hover {
  background-color: #0e3d4dcc;
}

.top-bar {
  height: 100px;
  text-align: center;
  background: white;
}

.map {
  flex-grow: 1;
}

.bottom-bar {
  height: 100px;
  text-align: center;
  background: white;
}

@media screen and (min-width: 1800px) {
  .container-md {
    padding: 0 32vh;
  }
}

@media screen and (min-width: 1600px) {
  .container-md {
    padding: 0 22vh;
  }
}

::v-deep {
  .button.add-btn .icon:first-child:last-child {
    margin-left: -10px;
    margin-right: -10px;
  }
  .modal-close {
    right: 38px;
    top: 38px;
  }
}
</style>
